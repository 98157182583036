@media screen and (min-width:992px) {


}
@media screen and (max-width:1700px){

	.step_sec3:before {
    background-size: contain;
    width: 125px;
    height: 130px;
	}
	.log_col2:after{
		top: 4%;
    right: 3%;    width: 75px;
	}
	.log_col2:before {
     
    width: 100px;
    height: 159px; 
    left: 8%; 
	}

	.step_sec4:before {
	    width: 330px;
	    height: 295px;
	    background-size: contain;
	}
	.step_sec4:after {
	    width: 375px;
	    height: 275px;
	    background-size: contain;
	}
	.hw_headblock.au_headblock {
	    padding-top: 0;
	}
	.ab_cols {
		padding-bottom: 100px;
	}
	.convi_sec {
		padding-top: 70px;
		padding-bottom: 80px;
	}
	.drp_col h3 {
		font-size: 26px;
	}
	.footer_sec {
		padding-bottom: 110px;
	}
	.step_sec4 {
	    padding-top: 100px;
	    padding-bottom: 150px;
	}
	.step_sec3 {
	    padding-top: 80px;
	    padding-bottom: 90px;
	}
	.step_sec2 {
	    padding-top: 130px;
	    padding-bottom: 90px;
	}
	.au_headblock .hw_midtitle {
	    top: 123px;
	}
	.inner_banner h1{
		font-size: 64px;
	}

}
@media screen and (max-width:1600px) {
	html{
		--heading2-size: 60px;
	}

	.home_banner h1{font-size: 60px;
    line-height: 68px;}
    .hw_midtitle h3{ font-size: 60px; }
    .hw_headblock h2{font-size: 200px;}
    .step_sec h3 {    font-size: 44px;}
    .step_sec h2{ font-size: 130px; }
    .step_sec1 img {
    margin-left: 0;
    max-width: none;
    width: 720px;
	}
	.au_headblock .hw_midtitle {
	    top: 100px;
	}
	.web_col1 h2,.convi_sec h2{font-size: 48px;}
	.abt_sec:before{    left: 5%;}
	.log_col1:after{
		    width: 180px;
		        top: 7%;
    right: 5%;
	}
	.log_col1:before{
		bottom: -2%;
    left: 0;
    width: 77px;
	}
	.log_col2:before {
    width: 75px;
    height: 109px;
    left: 7%;
    bottom: 1%;
	}
	.login_box{
		padding: 30px 50px;
	}
	.login_box h2{
		margin: 0 0 36px 0;
	}
	.frg_link {
    	margin-top: 25px;
	}
	.login_box p.sub_text{    margin: -15px 0 30px 0;}
	.convi_sec {
	    padding-top: 50px;
	}
	.step_sec.step_sec4 p {
	    padding-right: 90px;
	}
	.step_sec3 {
		padding-bottom: 65px;
	}
	.step_sec.step_sec4 .step_rt {
		position: relative;
		z-index: 9;
	}
	.step_sec2:before {
    	width: 150px;
    	background-size: contain;
    	background-repeat: no-repeat;
	}
	.step_sec2:after {
    	width: 300px;
    	background-size: contain;
    	background-repeat: no-repeat;
	}
	.bnr_left {
		padding-top: 180px;
	}
	.home_banner .header_grid {
		padding-bottom: 150px;
	}
	.bnr_right {
		margin-top: 150px;
	}
	.hw_headblock {
		padding-top: 0;
	}
	.step_sec1 {
		padding-bottom: 20px;
	}
	.hw_midtitle {
		top: 104px;
	}
	.inner_banner h1 {
    	font-size: 62px;
	}
	.inner_banner:before{
		width: 422px;
    	height: 236px;
    	background-size: contain;
	    background-repeat: no-repeat;
	}
	.inner_banner:after{
		width: 310px;
    	height:185px;
    	background-size: contain;
	    background-repeat: no-repeat;
	}
	.abt_sec1{
		padding-top: 90px;
        padding-bottom: 90px;
	}
	.abt_sec1.faq {
    	padding-top:75px;
	}
	.abt_sec1.terms {
   		padding-top: 40px;
	}
	.abt_sec1.terms h3{
		    font-size: 28px;
	}

}
@media screen and (max-width:1500px){
	.step_sec4 {
		padding-bottom: 100px;
	}
	.drp_col p, .ab_right p, .step_sec p, .bnr_left p {
		font-size: 16px;
		line-height: 1.55;
	}
	.hw_midtitle p {
		font-size: 18px;
	}

	.bnr_circle img {
    	width: 350px;
    	image-rendering: -webkit-optimize-contrast;
	}
	.shapes_img1{
		width: 150px;
	}
	.shapes_img2{
		width: 60px;
	}
	.shapes_img3 {
    	width: 80px;
	}
	.home_banner h1 {
    font-size: 55px;
    line-height: 61px;
	}
	.hw_headblock h2 {
    	font-size: 150px;
	}
	.hw_midtitle {
		top: 70px;
	}
	.step_sec1 {
	    padding-bottom: 40px;
	}
	.step_sec2 .step_rt {
		position: relative;
		z-index: 99;
	}
	.step_sec2:before {
	    width: 120px;
	}
	.step_sec2:after {
	    width: 200px;
	}
	.hw_midtitle h3 {
    	font-size: 55px;
	}
	.step_sec1 img {
    	width: 665px;
	}
	.step_sec h3 {
    	font-size: 40px;
	}
	
    .step_sec3:before { 
	    width: 100px;
	    height: 106px;
	    right: 1.5%;
	}
	.step_sec4 .step_imgwrap {
    	margin-left: -69px;
	}
	
	.abt_sec:before {
	    width: 85px;
	    background-repeat: no-repeat;
	    background-size: contain;
	}
	.drp_row:before { 
	    width: 82px;
	    height: 112px;     
	    top: -174px;
	    right: -70px;
	    background-size: contain;
	    background-repeat: no-repeat;
	}
	.web_col1 h2, .convi_sec h2 {
    	font-size: 43px;
	}
	
	.login_box input:not([type="submit"]){
		    padding: 20px 23px;
	}
	.login_box input[type="submit"]{padding: 16.5px 0;}
	.step_sec4:before {
	    width: 250px;
	    background-repeat: no-repeat;
	    background-position: right bottom;
	}
	.step_sec4:after {
	    width: 290px;
	    background-repeat: no-repeat;
	    background-position: right bottom;
	}
	.au_headblock .hw_midtitle {
	    top: 60px;
	}
	.drp_col h3 {
	    font-size: 24px;
	}
	.home_banner:before, .footer_sec:before {
	    width: 470px;
	    height: 263px;
	    background-size: contain;
	    background-repeat: no-repeat;
	}
	.home_banner:after, .footer_sec:after {
	    width: 390px;
	    height: 233px;
	    background-size: contain;
	    background-repeat: no-repeat;
	}
	.footer_sec {
	    padding-bottom: 70px;
	}
	.convi_sec {
		padding-bottom: 50px;
	}
	.inner_banner h1 {
    	font-size: 60px;
	}
	html{
		--heading2-size: 58px;
		--heading3-size: 44px;	
	}	

	.abtool_sec .col:not(.abtool_sec .col:nth-child(4),.abtool_sec .col:nth-child(5),.abtool_sec .col:nth-child(6)) {
    	min-height: 330px;
	}
	

}
@media screen and (max-width:1400px) {

	.step_sec2 .step_lt {
	    margin-top: 0;
	}
 
 	/*.shapes_img3 {
    bottom: 64px;
    left: 103px;
	}
	.shapes_img1 {
    	left: 40px;
    }*/
    .hw_headblock h2 {
    	font-size: 160px;
	}
	.step_sec1:before {
    width: 70px;
    height: 145px;
    top: -17px;
    left: 0;
    background-size: contain;
	}
	.step_sec h2 {
    	font-size: 140px;
	}
	.step_sec1 img {
    	width: 100%;
	}
	.step_sec2 h2 {
    	margin-top: 0;
	}
	.step_sec h3 {
    	font-size: 41px;
	}
	.inv_box h4{
		    font-size: 32px;
	}
	.step_sec4 .step_imgwrap {
    	margin-left: -35px;
	}
	/*.step_sec4 .step_wrapper {
    	margin-top: 160px;
	}
	.step_sec4 .step_lt:before{
		    top: 170px;
	}*/
	.log_col2:after{
		width: 60px;
	}
	.home_banner:before, .footer_sec:before {
	    width: 430px;
	    height: 241px;
	}
	.home_banner:after, .footer_sec:after {
	    width: 350px;
	    height: 209px;
	}
	.ftr_menu li a, .ftr_copy {
		font-size: 16px;
	}
	.inner_banner h1 {
   		font-size: 58px;
	}
	html {
    	--heading2-size: 56px;
    	--heading3-size: 42px;	
	}	
	
	.grid_1350{
		max-width: 1200px;
	}
	.abt_sec1{
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.abtool_sec .col:not(.abtool_sec .col:nth-child(4),.abtool_sec .col:nth-child(5),.abtool_sec .col:nth-child(6)) {
    	min-height: 320px;
	}
	.price .pkg_price, .price .pkg_price .dollar{
		font-size: 42px;
	}
	.price.prplan_row {
    	padding: 60px 0 80px;
	}
	.abt_sec1.faq {
    	padding-top: 70px;
	}
}
@media screen and (max-width:1300px){

	html {
    	--container: 1100px;
	}
	/*.bnr_circle img {
    width: 100%;
    margin-left: 0;
	}*/
	/*.shapes_img1{
		    top: 55px;
	}*/
	.hw_midtitle{
		    top: 73px;
	}
	.step_sec2 .step_lt:before {
	    width: 92px;
	    height: 119px;
	    left: -126px;
	    top: -79px;
	    background-size: contain;
	    background-repeat: no-repeat;
	}

	.home_banner h1 {
	    font-size: 50px;
	    line-height: 60px;
	}

	.bnr_left {
	    padding-top: 150px;
	}

	.step_sec4 {
		padding-top: 70px;
	}
	.ab_cols {
	    padding-bottom: 80px;
	}

	.drp_col h3 {
	    font-size: 22px;
	}

	.drp_col {
		padding-bottom: 50px;
	}
	.inner_banner h1 {
   		font-size: 56px;
	}
	html {
    	--heading2-size: 54px;
    	--heading3-size: 40px;	
	}	
	.grid_1350{
		max-width: 1100px;
	}
	.abt_sec1{
		padding-top: 75px;
		padding-bottom:75px;
	}
	.abtool_sec .col:not(.abtool_sec .col:nth-child(4),.abtool_sec .col:nth-child(5),.abtool_sec .col:nth-child(6)) {
    	min-height: 300px;
	}
	.inner_banner p{
		padding: 0px 160px;
	}
	.inner_banner p br{
		display: none;
	}
	.abt_sec2 {
	    padding-top: 50px;
	    padding-bottom: 60px;
	}
	.price .prplan_item{
		width: 33% ;
		padding: 0 15px;
	}
	.abt_sec1.terms h3{
		    font-size: 26px;
	}


}
@media screen and (max-width:1200px){

	html {
    	--container: 970px;
	}
	.top_menu li{
		    margin-right: 50px;
	}
	.frnt_header{
    	padding: 30px 0;
	}
	.usermenu li a{
		width: 115px;  font-size: 17px; padding: 7.5px 0;
	}
	.home_banner h1 {
    font-size: 42px;
    line-height: 1.15;
	}
	.bnr_left p{
    margin: 0 0 31px 0;
	}
	.home_banner .bnr_btn{
		    font-size: 17px; 
    padding: 9.5px 23px;
	}
	/*.home_banner:before {
    bottom: -109px;
    height: 380px;
	}*/
	.shapes_img1 {
    width: 100px;
    top: -60px;
	}
	.shapes_img2 {
    width: 40px;
    top: -70px;
	}
	.shapes_img3 {
    width: 60px;
    left: 0;
	}
	.hw_headblock h2 {
    	font-size: 126px;
	}
	.hw_midtitle h3 {
    	font-size: 47px;
	}
	.hw_midtitle {
    	top: 45px;
	}
	.hw_midtitle p{ font-size: 18px; }
	.step_sec h2 {
    	font-size: 100px;margin: 0 0 -60px;
	}
	.step_sec h3 {
    	font-size: 34px;    margin: 0 0 10px 0;
	}
	.step_sec1:before{
		width: 60px;
    height: 121px;
    top: -63px;
    left: 0;
    background-size: contain;
	}
	
	.step_sec2 {
    	padding-top: 50px;
    	padding-bottom: 50px;
	}	
	.step_sec2 .step_lt:before {
    width: 76px;
    height: 109px;
    left: -75px;
    top: -40px;
    }
    .step_sec3 .step_wrapper {
    	margin-right: 0;
    }
    .step_sec4:before, .step_sec4:after {
    	display: none;
    }
    .step_sec.step_sec4 p {
	    padding-right: 30px;
	}
    .step_sec3 .step_imgwrap img {
    margin-left: 0;
	}
	.step_sec3 {
    	padding-top: 50px;
    	padding-bottom: 50px;
	}
	.step_sec3:before {
    width: 78px;
    height: 81px;
    right: 1.5%;
    top: 20px;
	}
	.step_sec2 .step_wrapper {
	    margin-left: 0;
	}
	.step_sec2:before, .step_sec2:after {
		display: none;
	}
	.step_sec4 .step_imgwrap {
    margin-left: 0;
	}
	.step_sec4 .step_wrapper {
    margin-top: 0;
	}
	.step_sec4 .step_lt:before {
    	top: 85px;
	}
	.abt_sec {
    padding-top: 65px;
    padding-bottom: 37px;
    }
    .web_col1 h2, .convi_sec h2 {
    font-size: 36px;
	}
	.web_col1 h2 {
    line-height: 1.2;
	}
	.web_col2 p {
    font-size: 17px;
    line-height: 27px;
    }
    .web_col2 a{
    	font-size: 17px;    padding: 8.5px 24px;
    }
    .drp_row:before {
    width: 59px;
    height: 112px;
    top: -21%;
    right: 0;
    }
    .drp_row{
    	grid-column-gap: 2%;
	    column-gap: 2%;
	    grid-template-columns: 49% 49%;
    }
    .drp_col h3 {
    margin: 21px 0 9px 0;
    font-size: 20px;	
	}
	.drp_col{
		    padding: 25px 30px 40px;
	}
	.drp_col img {
    height: 100px;
	}
	.convi_sec {
    	padding-bottom: 30px;
    	padding-top: 40px;
	}
	.convi_sec h2 {
		margin-bottom: 30px;
	}
	
    .top_menu li a{ font-size: 17px; }
    .hw_headblock {
    	margin-top: 0;
	}
	.page_body {
    	padding-top: 104px;
	}
	.log_col1:after {
    width: 110px;
    top: 5%;}
    .log_col1:before {
    width: 55px;
    height: 140px;
	}	
	.log_col1 h2 {
    font-size: 40px;
    line-height: 43px;
    }
    .login_block {
    	width: 75%;
	}
	.login_box {
    	padding: 30px;
	}
	.login_box h2 {
    	font-size: 29px;
	}
	.gngo_link a:before{
		width: 30px;
    height: 30px;    background-size: contain;
	}
	.gngo_link a {
    font-size: 17px;
    padding: 10px 22px 10px 50px;
    }
    .or_line {
    	padding: 18px 0;
	}
	.login_box input:not([type="submit"]) {
    padding: 15px 19px;
	}
	.login_box input[type="submit"] {
    	padding: 11.5px 0;
	}
	.link_btn{ font-size: 17px; }
	.logo_login {
    top: 30px;
    left: 28px;
	}

	.bnr_circle img {
		width: 300px;
	}
	.bnr_left {
		padding-top: 60px;
	}
	.bnr_right {
	    margin-top: 10px;
	}
	.step_sec4 {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.step_sec4 img {
		width: 300px;
	}
	.ab_cols {
		padding-bottom: 50px;
	}
	.ab_btn {
	    padding-top: 0;
	}
	
	.home_banner:before, .footer_sec:before {
	    width: 350px;
	    height: 196px;
	}
	.home_banner:after, .footer_sec:after {
	    width: 300px;
	    height: 179px;
	}
	.inner_banner h1 {
   		font-size: 54px;
	}
	.abt_sec2 h2{
		margin: 0 45px 55px 45px;
	}
	html {
    	--heading2-size: 50px;
    	--heading3-size: 36px;	
	}	
	.grid_1350{
		max-width: 950px;
	}
	.abt_sec1{
		padding-top: 70px;
		padding-bottom:70px;
	}
	.abt_sec1 .col7 {
    	padding-left: 40px;
	}
	.inner_banner:before {
	    width: 400px;
	    height: 224px;
	}
	.inner_banner:after {
	    width: 318px;
	    height: 190px;
	}
	.abt_sec2 .col7{
		margin-bottom: 30px;
	}
	.accordion a{
		font-size: 18px;
	}
	.accordion-content{
		padding: 15px 50px 13px 0;
	}
	[id*="open-accordion"]{
		    padding: 15px 0px;
	}
	[id*="open-accordion"]:after, [id*="close-accordion"]:after{
		right: 30px;
    	top: 32px;
	}
	.abt_sec1.faq_section .col7{
		    padding-left: 50px;
		    padding-right: 0px;
	}
	.abt_sec1 .grid_1000 h2 {
	    margin-bottom: 25PX;
	    padding-left: 15px;
	}
	
}
@media (min-width:992px) and (max-width:1200px) {}

@media screen and (max-width:991px) {

	html {
    	--container: 720px;
	}
	.header_grid{ width: calc(100% - 30px); position: relative; }
	.nav_rt {
		width: auto;
		margin-left: auto;
		margin-right: 50px;
	}
	.top_menu li {
    	margin-right: 35px;
	}
	.mobinav span:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    background: #fff;
    left: 0;
    pointer-events: none;
	}
	.mobinav span:after {
    content: "";
    width: 19px;
    height: 2px;
    position: absolute;
    bottom: 0;
    background: #fff;
    right: 0;
    pointer-events: none;
	}
	.mobinav span {
    width: 35px;
    height: 2px;
    display: block;
    background: #fff;
	}
	.mobinav {
    display: block;
    position: absolute;
    top: 11px;
    right: 0px;
    cursor: pointer;
    padding: 7px 0;
    z-index: 9;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
	}
	.top_menu {
    height: 100%;
    width: 300px;
    position: fixed;
    right: -300px;
    top: 0;
    background: #005450;
    z-index: 99;
    padding: 0;
    margin: 0 !important;
    overflow-y: auto;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
	}
	.top_menu.open {
    right: 0;
	}
	.navclsbtn {
    display: block;
    padding: 0;
    text-align: right;
    cursor: pointer;
    position: relative;
    padding-left: 33px;
    width: 21px;
    float: right;
    height: 25px;
    margin-top: 15px;
    margin-right: 15px;
	}
	 .navclsbtn:after {
        content: "";
        position: absolute;
        width: 3px;
        height: 22px;
        background: #fff;
        left: 20px;
        top: 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
    }
    .navclsbtn:before {
        content: "";
        position: absolute;
        width: 3px;
        height: 22px;
        background: #fff;
        left: 20px;
        top: 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
    	}

    	.frnt_header, .frnt_header.dash_header {
    		padding: 20px 0;
		}
		.usermenu {
    	margin-right: 75px;
			margin-left: auto;
	}
	.bnr_circle img {
    margin-top: 0;
    width: 250px;
	}
	 
	.top_menu li a {
    font-size: 16px;
    display: block;
    padding: 15px 20px;
	}
	.top_menu li {
    margin-right: 0;
    display: block;
    float: none;
    border-bottom: 1px solid #0d6864;
	}
	.top_menu ul {
    margin-top: 45px;
	}
	.usermenu li a{ font-size: 16px; }
	.home_banner .header_grid {
    	display: block;
    	padding-bottom: 100px;
	}
	.hw_midtitle h3 {
	    font-size: 40px;
	}
	.hw_midtitle p {
	    font-size: 16px;
	}
	.bnr_left {
    width: 100%;
    float: none;
	text-align: center;
	padding-top: 40px;
	}
	.bnr_right {
    float: none;
    width: 100%;
    position: relative;
    text-align: center;
    margin-top: 0;
    overflow: hidden;
	}
	.fl_right{float:none; order: 1;}
	.bnr_circle {
    max-width: 500px;
    margin: 60px auto 70px;
	}
	.shapes_img1, .shapes_img2 {
		top: 0;
	}
	.shapes_img3 {
		bottom: 0;
	}
	.shapes_outer {
    max-width: 450px;
    margin: auto;
    left: 0;
	}
	.home_banner h1 {
    font-size: 30px;
    line-height: 1.1;
    margin-bottom: 10px;
	}
	.home_banner:before, .footer_sec:before {
	    width: 300px;
	    height: 168px;
	}
	.home_banner:after, .footer_sec:after {
	    width: 250px;
	    height: 150px;
	}
	.bnr_left p {
    margin: 0 0 20px 0;
	}
	.home_banner .bnr_btn {
    font-size: 16px;
    padding: 8.5px 20px;
	}
	.hw_headblock h2 {
    font-size: 110px;
	}
	.hw_headblock {
    	margin-top: 0;
	}
	.hw_midtitle {
    	top: 50px;
	}
	.step_sec:after {
		content: "";
		display: block;
		clear: both;
		background-image: none;
	}
	.step_rt,.step_lt{ width: 100%; float: none; text-align: center; }
	.step_sec h2 {    font-size: 105px;    margin: 0 0 -86px 0;}
	.inv_box {
	    margin: auto;
	    padding: 25px 20px
	}
    .inv_box h4 {
    	font-size: 28px;
	}
	.inv_box a {
	    font-size: 16px;
	    padding: 8.5px 25px;
	}
	.step_sec2 .step_wrapper {
    	margin-left: 0;
	}
	.step_sec2 .step_lt p {
    	padding-right: 0;
	}
	.step_sec3 .step_wrapper {
	    margin-right: 0;
	    margin-top: 0;
	}
	.step_sec1 img {
	    max-width: 400px;
	    width: 100%;
	    margin-bottom: -30px;
	}
	.step_sec1 {
	    padding-bottom: 50px;
	}
	.step_sec3 .step_imgwrap img {
		width: 350px;
	}
	.step_sec4 .step_wrapper {
	    margin-top: 0;
	    margin-left: 0;
	}
	.step_sec.step_sec4 p {
	    padding-right: 0;
	}
	.step_sec4 img {
	    max-width: 225px;
	    margin: 0 auto;
	    display: block;
	    width: 100%;
	}
	.step_sec4 .step_lt:before {
	    top: 0;
	    width: 100px;
	    height: 50px;
	}
	.au_headblock .hw_midtitle {
	    top: 40px;
	}
	.ab_right p {
		margin-bottom: 15px;
	}
	.ab_btn {
	    padding-top: 10px;
	}
	.home_banner .bnr_btn, .ab_btn a {
		font-size: 16px;
	}
	.web_col1 {
	    width: 100%;
	    float: none;
	}
	.web_col2 {
	    float: none;
	    width: 100%;
	}
	.web_col1 h2, .convi_sec h2 {
    	font-size: 32px;
	}
	.web_col1 h2 {
	    line-height: 1.2;
	    text-align: left;
	    margin-bottom: 20px;
	}
	.abt_sec:before {
	    width: 56px;
	    background-repeat: no-repeat;
	    background-size: contain;
	    left: 1%;
	    top: 14%;
	}
	.web_col2 a {
    	font-size: 16px;
    }
    .convi_sec h2 {
    	margin: 0 0 25px 0;
    }
    .drp_row{ 
    	display: block; 
    }
    .drp_col {
	    min-height: auto;
	    margin-bottom: 30px;
	}
	.drp_col:last-of-type {
		margin-bottom: 0;
	}
	.drp_col h3 {
	    margin: 18px 0 9px 0;
	    font-size: 22px;
	}
	.footer_sec:before, .footer_sec:after {
		display: none;
	}
	.convi_sec {
    	padding-bottom:30px;
	}
	.ftr_copy {
		margin-top: 20px;
	}
	
	.footer_sec{
		padding-top: 35px;
    	padding-bottom: 35px;
	}
	.step_sec h3 {
    	font-size: 32px;
    }
    .step_sec2 .step_lt:before{
    	 left: -2%;
    }
    .step_sec3:before{
    	top: 78px;
    }
    
	.page_body {
    	padding-top: 100px;
	}
	.frnt_header{
		z-index: 99;
	}
	.loginouter{ 
		height: 100%; 
	}
	.log_wrapper {
	    width: 100%;
	    float: none;
	    padding: 75px 0;
	}
    .log_col1{ 
    	padding: 125px 0;
    }
    .log_col2:before {
	    width: 60px;
	    height: 89px;
	    bottom: 1%;
	}
	.log_col1 h2 {
	    font-size: 38px;
	    line-height: 40px;
	}
    .login_box p.sub_text {
    	margin: -9px 0 21px 0;
	}
	.inv_box img {
		margin-left: auto;
		margin-right: auto;
	}
	.inner_banner h1 {
    	font-size: 32px;
	}
	.inner_banner p {
    	padding: 0px 10px;
	}
	.grid_1350 {
	    max-width: 100%;
	    padding: 0px 40px;
	}
	.abt_sec1 .col5, .abt_sec1 .col7{
		width: 100%;
	}
	.abt_sec1 .col7 {
    	padding-left: 10px;
	}
	.abt_sec1 h2{
		margin-bottom: 20px;		
	}

	.abtool_sec .col:not(.abtool_sec .col:nth-child(4),.abtool_sec .col:nth-child(5),.abtool_sec .col:nth-child(6)) {
    	    min-height: auto!important;
	}
	.abtool_sec .col4{
		width: 50%;
	}
	.abtool_sec .col4 p{
		min-height: 110px;
	}
	html {
    	--heading2-size: 40px;
    	--heading3-size: 32px;	
	}
	.abtool_sec h3{
		font-size: 20px;
	}
	.inner_banner:before {
	    width: 285px;
	    height: 160px;
	   
	}
	.inner_banner:after {
	    width: 250px;
	    height: 150px;	    
	}
	.abt_sec1 p ,.abt_sec2 p{
		 font-size: 16px;
		 line-height: 26px;
	}
	.abtool_sec {
    	padding-top: 50px;
	}
	.abt_sec2 p{
		font-size: 16px;
		margin: 0 0 25px 0;
	}
	.abt_sec2 h2 {
    	margin: 0 30px 40px 30px;
	}
	.abt_sec2 {
	    padding-top: 55px;
	    padding-bottom: 55px;
	}
	.price_inner h1 {
    	line-height: 1.1!important;
	}
	.price .prplan_item {
	    max-width: 450px;
	    width: 100%;
	    padding: 0 0px 35px 0px!important;
	    float: none;
	    margin: 0 auto;
	}
	.abt_sec1.faq_section .col5{
 		text-align: center;
	}
	.faq_section .col5 h2 {
    	padding-bottom: 0px;
	}
	.faq_section .ab_btn {
	    padding-top: 0px;
	    margin-bottom: 50px;
	}
	.abt_sec1.faq_section .col7 {
	    padding-left: 00px;
	    padding-right: 0px;
	}
	.price.prplan_row {
    	padding: 35px 0 25px;
	}
	.price .pkg_price, .price .pkg_price .dollar {
   		 font-size: 38px;
	}
	.price .mth{
		margin-bottom: 35px;
	}
	.price .prplan_item .prplan_grid{
		padding: 30px 30px 20px 30px;
	}
	.price .mst_label{
	   margin-top: -50px;
       margin-bottom: 25px
	}
	.abt_sec1.faq {
    	padding-top:60px;
	}
	.abt_sec1.terms p{
		font-size: 16px;
		 margin: 0 0 15px 0;
	}
	.abt_sec1.terms h3 {
    	font-size: 24px;
	}
	.termsofuse .inner_banner h1 {
    	margin: 0 0 15px 0;
	}
	
	.prplan_row {    margin-left:0px; margin-right: 0;}
	.error_block {
		padding: 15px;
		font-size: 16px;
		letter-spacing: 0;
	}
}
@media screen and (max-width:768px) {}

@media screen and (max-width:767px) {

	html {
    	--container: 94%;
	}
	.header_grid {
    	width: var(--container);
    }
    /*.home_banner h1 {    font-size: 35px;}*/
    .hw_headblock h2 {
    	font-size: 88px;
	}
	.step_sec h3 {
    	font-size: 31px;
	}
	.step_sec2 .step_lt:before {
	    width: 58px;
	    height: 92px;
	}
    .step_sec h2 {    
    	font-size: 95px;
    }
    /*.web_col2 p,.step_sec p,.drp_col p {
    font-size: 16px;
    line-height: 26px;
	}*/
	.web_col1 h2, .convi_sec h2 {
    	font-size: 30px;
	}
	.abt_sec {
	    padding-top: 45px;
	    padding-bottom: 50px;
	}
	.abt_sec:before {
	    width: 45px;
	    left: 1%;
	    top: 1%;
	}
	
	/*.shapes_img1 {
    top: 38px;
    width: 100px;
    left: 9px;
	}
	.shapes_img3 {
    left: 40px;
    width: 52px;
	}
	.shapes_img2 {
    width: 50px;
    right: 22px;
    top: 45px;
	}*/
	/*.hw_headblock {
    	margin-top: 18px;
	}*/
	.hw_midtitle {
	    top: 40px;
	}
	.hw_headblock {
		padding-top: 20px;
	}
	.log_col1:after {
	    width: 85px;
	    top: 6%;
	    right: 3%;
	}
	.ab_left {
	    width: 100%;
	    float: none;
	    text-align: center;
	    margin-bottom: 40px;
	}
	.ab_left img {
		width: 250px;
	}
	.ab_right {
	    width: 100%;
	    float: none;
	}
	.hw_headblock.au_headblock {
		padding-bottom: 30px;
	}
	.abtool_sec .col4{
		width: 100%;		
		text-align: center;

	}
	.tl_box,.tl_box p {
	   	 max-width: 100%;
	   	 text-align: center;
	}
	.abtool_sec .col4 p {
	    min-height: auto;
	    margin-bottom: 25px;
	}
	.abt_sec2 .col5 {
	    width: 100%;
	    text-align: center;
	}
	.abt_sec2 .col5 img{
		max-width: 450px;
	}
	.abt_sec2 {
	    padding-top: 50px;
	    padding-bottom: 55px;
	}

	.abt_sec2 .col7{
		width: 100%;
	    padding-left: 10px;
	    margin-bottom: 30px;
	    padding-top: 45px;
	}
	.abt_sec2 h2 {
    	margin: 0 45px 20px 45px;    	
	}	
	.abt_sec1 h2 {
	    margin-bottom: 20px;
	}
	.abt_sec1 {
	    padding-top: 50px;
	    padding-bottom: 40px;
	}
	.inner_banner{
		padding-bottom: 100px;
	}
	.inner_banner:before {
	    width: 300px;
	    height: 168px;
	}
	.inner_banner:after {
	    width: 228px;
	    height: 136px;
	}
	.inner_banner h1 {
    	font-size: 30px;
    	line-height: 1.1;
	}
	html {
    	--heading2-size: 36px;
    	--heading3-size: 31px;	
	}	
	.accordion a {
	    font-size: 16px;
	 }
	 .price .pkg_price, .price .pkg_price .dollar {
   		 font-size: 36px;
	}
	.price.prplan_row {
    	padding: 20px 0 10px;
	}
	.faq_section .ab_btn{
		margin-bottom: 35px;
	}
	.abt_sec1.faq {
   		 padding-top: 50px;
	}
	.abt_sec1 .grid_1000 h2 {
    	margin-bottom: 25PX;
        padding-left: 12px;
	}
	.abt_sec1.terms {
    	padding-top: 20px;
	}
	.abt_sec1.terms h3 {
    	font-size: 22px;
	}
	.log_col1 h2 {
	    font-size: 30px;
	}
	
	
}
@media screen and (max-width:667px) {
	.hw_midtitle h3 {
	    font-size: 36px;
	}
	.step_sec h3 {
		margin-bottom: 20px;
	}
	.drp_col p, .ab_right p, .step_sec p, .bnr_left p, .hw_midtitle p, .ftr_menu li a, .ftr_copy {
		font-size: 15px;
	}
	.convi_sec {
		padding-top: 30px;
		padding-bottom: 15px;
	}
	.ftr_logo img {
		width: 200px;
	}
	.drp_col h3 {
		font-size: 20px;
	}
	.drp_col {
		padding-bottom: 30px;
	}
	.step_sec4 {
		padding-bottom: 40px;
	}
	.step_sec4 img {
	    max-width: 150px;
	}
	.inner_banner:before {
	    width: 260px;
	    height: 146px;
	}
	.inner_banner:after {
	    width: 203px;
	    height: 122px;
	}
	.abt_sec1 {
	    padding-top: 45px;
	    padding-bottom: 25px;
	}
	.abt_sec2 {
	    padding-top: 35px;
	    padding-bottom: 50px;
	}
	
}

@media screen and (max-width:640px) {
	.hw_midtitle h3 {
	    font-size: 36px;
	}
	.convi_sec {
		padding-top: 30px;
	}
	.drp_col {
		padding-bottom: 35px;
	}
	.ftr_logo img {
		width: 180px;
	}
	.ftr_menu li a, .ftr_copy {
	    font-size: 15px;
	}
	.step_sec2, .step_sec3 {
	    padding-top: 40px;
	    padding-bottom: 40px;
	}
	.inner_banner p{
		font-size: 15px;   
		line-height: 24px;
	}
	.inner_banner {
    	padding-bottom: 70px;
	}
	.grid_1350 {   
    	padding: 0px 20px;
	}

	.price .prplan_item{
		width: 90%;
	}
	.faq_section .ab_btn a{
		width: auto;
		padding: 10px 30px;
	}
	.accordion a {
	   line-height: 24px;
	   padding-right: 55px;
	}
	[id*="open-accordion"]:after, [id*="close-accordion"]:after{
		top: 24px;
		right: 25px
	}
	.accordion-content {
   		padding: 15px 10px 13px 0;
	}
	.price .prplan_item .prplan_grid{
		padding: 30px 20px 20px 20px;
	}

}

@media screen and (max-width:568px) {
	.usermenu {
	    margin-right: 60px;
	}
	.ftr_menu li {
		padding: 0 10px 0 0;
	}
	.web_col1 h2, .convi_sec h2 {
	    font-size: 28px;
	}
	.abt_sec2 .col5 img{
		max-width: 90%;
	}
	.abt_sec2 h2{
		margin: 0 0px 20px 0px;
	}
	html {
    	--heading2-size: 32px;
    	--heading3-size: 28px;	
	}
	.abt_sec1 .grid_1000 h2 {
	    margin-bottom: 25PX;
	    padding-left: 0;
	}
}

@media screen and (max-width:533px) {

	.hw_midtitle h3 {
    	font-size: 34px;
	}
	.hw_headblock h2 {
    	font-size: 77px;
	}
	.logo_lt {
	    width: 100%;
	    float: none;
	    text-align: center;
	    margin-bottom: 20px;
	}
	.main_logo img {
	    width: 170px;
	    image-rendering: -webkit-optimize-contrast;
	}
	.usermenu {
	    margin-right: 0;
	    float: none;
	    text-align: center;
	    margin: 0;
	}
	.nav_rt .fl_right {
		width: 100%;
    	justify-content: center;
	}
	.ftr_menu li {
    	margin: 5px;
	}
	.page_body {
    	padding-top: 140px;
	}
	.fixed_header .usermenu{ display: none; }
	.fixed_header .logo_lt{ margin-bottom: 0; }
	.usermenu li a {
	    font-size: 15px;
	    width: 100px;
	    padding: 5px 0 7px;
	}
	.home_banner:before, .footer_sec:before {
	    width: 200px;
	    height: 112px;
	}
	.home_banner:after, .footer_sec:after {
	    width: 150px;
	    height: 90px;
	}
	.home_banner .header_grid {
		padding-bottom: 70px;
	}
	.fixed_header .mobinav {
		top: 8px;
	}
	.shapes_img1 {
		left: 0;
	}
	.shapes_img2 {
		right: 0;
	}
	.step_sec h3 {
	    font-size: 28px;
	}
	.step_sec1 {
	    padding-bottom: 40px;
	}
	.abt_sec1.terms {
    	padding-top: 15px;
	}
	.abt_sec1.terms h3 {    
    	line-height: 1.1;
	}
	.header_grid {
		flex-wrap: wrap;
	}
}

@media screen and (max-width:480px) {
	.hw_midtitle h3 {
    	font-size: 32px;
	}
	.hw_headblock h2 {
    	font-size: 66px;
	}
	.home_banner h1,.inner_banner h1 {    
	    font-size: 28px;
	}
	.shapes_img2 {
	    width: 30px;
	}
	.shapes_img1 {
	    width: 70px;
	}
	.shapes_img3 {
	    width: 45px;
	}
	.bnr_circle {
		margin: 50px auto 60px;
	}
	.step_sec h2 {
	    font-size: 90px;
	}
	html {
    	--heading2-size: 28px;
    	--heading3-size: 27px;	
	}
	.inner_banner:before {
	    width: 230px;
	    height: 129px;
	}
	.inner_banner:after {
	    width: 175px;
    	height: 105px
	}
	.abtool_sec h3 {
    	font-size: 18px;
	}
	.price .prplan_item {
    	width: 96% ;
	}
	.price_inner h1 br{
		display: none;
	}
	.login_box {
	    padding: 20px;
	}
}

@media screen and (max-width:440px){

	.home_banner h1 {
    	font-size: 28px;
	}
	.hw_midtitle h3 {
    	font-size: 28px;
	}
	.hw_headblock h2 {
    	font-size: 54px;
	}
	.hw_midtitle {
    	top: 29px;
	}
	.step_sec1:before {
	    width: 45px;
	    height: 91px;
	    top: -128px;
	    left: 0;
	    background-size: contain;
	}
	.step_sec h3 {
    	font-size: 27px;
	}
	.inv_box h4 {
    	font-size: 24px;
	}
	.step_sec2 .step_lt:before {
	    width: 45px;
	    height: 80px;
        top: 1%;
	}
	.step_sec3:before {
	    width: 61px;
	    height: 64px;
	}
	.step_sec h2 {
	    font-size: 89px;
	    margin: 0 0 -75px 0;
	}
    .web_col1 h2, .convi_sec h2 {
    	font-size: 27px;
	}
	.step_sec4 {
		padding-top: 40px;
	}
	.convi_sec h2 {
    	line-height: 1.33;
	}
	.drp_row:before {
	    width: 46px;
	    height: 112px;
	    top: -24%;
	    right: 0;
	}
	.drp_col h3 {
    	font-size: 19px;
	}
	.ftr_logo img {
    	width: 150px;
	}
	.ftr_copy{
		margin-top: 15px;
	}
	.ftr_menu ul {
		margin-top: 15px;
	}
	.step_sec3 .step_imgwrap img {
	    width: 250px;
	}
	.step_sec1 img {
	    max-width: 300px;
	}
	.inv_box img {
    	margin: 14px auto 21px;
	}
	.footer_sec:after {
	    width: 100%;
	    height: 1005px;
	    bottom: 0;
	    left: 0;
	    background-size: auto 100%;
	    background-position: bottom center;
	    background-repeat: no-repeat;
	}
	.logo_login {
	    /*top: 22px;
	    left: 20px;*/
    	margin-top: 100px;
	}
	.log_col1 h2 {
	    font-size: 31px;
	    line-height: 33px;
	}
	.log_col1 a.link_btn {
	    padding: 10.5px 30px;
	    font-size: 16px;
	}
	.login_block {
    	width: 85%;
	}
	.log_col2:after {
    	width: 36px;
	}
	.log_col2:before {
	    width: 41px;
	    height: 75px;
	    bottom: 1%;
	    left: 3%;
	}
	.login_box input[type="submit"] {
	    padding: 10.5px 0;
	    font-size: 16px;
	}
	.login_box h2 {
	    margin: 0 0 20px;
	    font-size: 26px;
	}
	.login_box input:not([type="submit"]) {
    	padding: 13px 19px;
	}
	.gngo_link a {
    	font-size: 16px;
    }
    .log_col1 {
    	padding: 89px 0;
	}

	.hw_headblock.au_headblock {
	    padding-top: 30px;
	}
	.ab_cols {
	    padding-bottom: 40px;
	}
	.main_logo img {
		width: 150px;
	}
	.mobinav {
		top: 11px;
	}
	.usermenu li, .usermenu li:first-child {
		margin: 0 5px;
	}
	.nav_rt {
		margin-right: 45px;
	}
	.abt_sec1.terms h3 {
      font-size: 20px;   	
	}	
	.abt_sec1.terms p {
    	font-size: 15px;
    }
    .usermenu li a {
	    font-size: 14px;
	    width: 80px;
	    padding: 3px 0 5px;
	}
}


@media screen and (max-width:400px) {
	.usermenu {
		width: 100%;
		margin: 15px 0 0;
	}
}
@media screen and (max-width:375px) {

	html {
    	--container: 90%;
	}
	.hw_midtitle h3 {
    	font-size: 26px;
	}
	.shapes_img1{
		width: 70px;
	}
	.shapes_img2 {
       width: 34px;
	}
    .shapes_img3 {
	    left: 21px;
	    width: 39px;
	}
	.faq_section .grid_1350 {
    	padding: 0px 10px;
	}
	.accordion a {  
      
    padding-right: 40px;
	}
	
	[id*="open-accordion"]:after, [id*="close-accordion"]:after{
		right: 18px;
	}
	.abt_sec1 .grid_1000 h2 {
    	margin-bottom: 20PX;
        padding-left: 5px;
	}



}

@media screen and (max-width:360px) {}

@media screen and (max-width:320px) {

	.home_banner h1,.inner_banner h1 {
    	font-size: 24px;
	}
	.hw_midtitle h3 {
    	font-size: 22px;
	}
	.hw_headblock h2 {
    	font-size: 45px;
	}
	.step_sec h3 {
    	font-size: 24px;
	}
	.accordion a {
	    font-size: 14px;
	    line-height: 22px;    
	    padding-right: 35px;
	}

	[id*="open-accordion"]:after, [id*="close-accordion"]:after{
		right: 15px;
	}
	

}
@media screen and (max-height:1024px) {
	.loginouter{
		height:100%;
	}
}