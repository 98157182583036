@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&display=swap'); 
 

input[type="submit"],
input[type="button"],
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

 
input[type="number"] {
    -moz-appearance: textfield;
}


html{
    --btnbg:linear-gradient(to right, #068466 0%, #068466 100%);
    --moz-linear-btnbg:-moz-linear-gradient(left,  #068466 0%, #068466 100%);
   --webkit-btnbg:-webkit-linear-gradient(left,  #068466 0%, #068466 100%);

   --btnbghover:linear-gradient(to right, #94ffdb 0%, #b6ff9b 100%);
   --moz-linear-btnbghover:-moz-linear-gradient(left,  #94ffdb 0%, #b6ff9b 100%);
   --webkit-btnbghover:-webkit-linear-gradient(left,  #94ffdb 0%, #b6ff9b 100%);

   --container:1200px;
   --heading2-size:66px;
   --heading2-weight:700; 

   --heading3-size:48px;
}
a,
a:hover,
input[type="submit"],
button {
    text-decoration: none;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
img {
    image-rendering: -webkit-optimize-contrast;
}
*:focus {
    outline: none;
}

:after,:before{ pointer-events: none; }
.web_container{ width: var(--container); margin: auto; }
.frnt_header {
    /*background: #031a28;*/
    padding: 48px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.page_body {
    /*background: #031b29;*/
    padding-top: 146px;
}
.header_grid {
    width: 90%;
    max-width: 1530px;
    margin: auto;
    display: flex;
    align-items: center;
}
.frnt_header:after{ content: ""; clear: both; display: table; }
.logo_lt {
    width: 20%;
    float: left;
}
.nav_rt {
    float: right;
    width: 80%;
}
.top_menu li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 74px;
}
.top_menu li a {
    color: #7fa9a7;
    font-size: 18px;
    letter-spacing: 0.54px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}
.top_menu {
    /*display: inline-block;
    vertical-align: middle;
    margin-top: 9px;*/
    transition: all .2s ease-in-out;
}
.main_logo {
   transition: all .2s ease-in-out; 
}
.usermenu {
    float: right;
}
.usermenu li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
}
.usermenu li a {
    border-radius: 3px;
    background-color: #fff;
    color: #005450;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    width: 124px;
    display: block;
    text-align: center;
    padding: 7.5px 0 11.5px;
    border: 2px solid #fff;
    transition: all .2s ease-in-out;
}
.home_banner .bnr_btn:hover,
.ab_btn a:hover,
.inv_box a:hover,
.web_col2 a:hover,
.surp_page .bnr_btn:hover{
        /*background: var(--moz-linear-btnbghover);
    background: var(--webkit-btnbghover);
    background: var(--btnbghover);
    color: #000;*/
    color: #000;
    background-color: #ffc776;
}
.usermenu li a:hover {
    background-color: #407f7c;
    border-color: #407f7c;
    color: #fff;
}
/*.ftr_menu li a:hover{    color: #fff;}*/
.frnt_header ul {
    margin: 0;
    padding: 0;
}
.fl_right{ float: right; }
.usermenu li:first-child {
    margin-left: 0;
}
.top_menu li a:hover,
.top_menu li.active a{
    color: #fff;
}
li.suplink a {
    /*   background: var(--moz-linear-btnbg);
         background: var(--webkit-btnbg);
    background: var(--btnbg);*/
    background-color: transparent;
    border: 2px solid #407f7c;
    color: #fff;
}
.home_banner {
    font-family: 'Poppins', sans-serif;
    position: relative;
}
.home_banner:after{ clear: both; display: table; content: ""; }
 

/*.home_banner:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1142px;
     background: url(../../assets/images/wave_bg.png);
    background-size: 100% auto;
    bottom: -291px;
    left: 0;
    z-index: 99;
    background-position: bottom center;
    background-repeat: no-repeat;
  
}*/
 
.home_banner h1 {
    margin: 0 0 24px;
    color: #ffffff;
    font-size: 66px;
    line-height: 72px;
        font-weight: 700;
}
.bnr_left {
    width: 41%;
    float: left;
    padding-top: 202px;
}
.bnr_right {
    float: right;
    width: 51.6%;
    position: relative;
    margin-top: 230px;
}
.bnr_left p {
    color: #fff;
    font-size: 18px;
    margin: 0 0 37px 0;
    letter-spacing: 0.18px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    line-height: 30px;

}
.home_banner .bnr_btn, .ab_btn a, .surp_page .bnr_btn {
    /*background: var(--moz-linear-btnbg);
    background: var(--webkit-btnbg);
    background: var(--btnbg);*/
    border-radius: 2px;
    background-color: #068466;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 9.5px 28px 13.5px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
}

/*.bnr_circle img {
    width: 881px;
}*/

.bnr_circle {
    text-align: center;
}

.home_banner .header_grid{
       display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        -webkit-box-pack: space-between;
        -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
                justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; 
                padding-bottom: 209px;
}

.shapes_outer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.shapes_img1 {
    position: absolute;
        left: -34px;
    top: -110px;
}
.shapes_img2 {
    position: absolute;
    right: 34px;
    top: -82px;
}
.shapes_img3 {
    bottom: -102px;
    position: absolute;
    left: 36px;
}
.bnr_circle img{ 
        /*margin-left: 60px;
    margin-top: -25px;*/
    z-index: 11;
    position: relative;
}
/*.page_body {
    background: #031b29;
}*/

.home_banner:before, .footer_sec:before,.inner_banner:before {
    content: "";
    width: 536px;
    height: 300px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url(../../assets/images/yellow-shape1.svg);
}

.home_banner:after, .footer_sec:after,.inner_banner:after {
    content: "";
    width: 452px;
    height: 270px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url(../../assets/images/line-shape.svg);
}

.hw_headblock h2 {
    text-align: center;
    margin: 0;
    color: rgba(221,235,232,0.4);
    font-size: 250px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: transparent;
    pointer-events: none;
}
.hw_headblock {
    position: relative;
    background-color: #fff;
        /*margin-top: -45px;*/
        padding-top: 38px;
}
.hw_midtitle {
    position: absolute;
        top: 154px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.hw_midtitle h3 {
    color: #353640;
    font-size: 66px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0 0 -3px 0;
}
.hw_midtitle p {
    margin: 0;
    color: #353640;
    font-size: 20px;
    letter-spacing: 0.2px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
}
.step_lt {
    width: 50%;
    float: left;
}
.step_rt {
    width: 50%;
    float: left;
}
.step_sec{ font-family: 'Poppins', sans-serif; position: relative;}
.step_row:after{ content:""; clear:both; display: table; overflow: hidden; }
.step_sec h2 {
    color: #dfece9;
    font-size: 150px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 -90px 0;
}
.step_sec h3 {
    font-size: 48px;
    color: #353640;
    margin: 0 0 18px 0;
}
.step_sec p {
    color: #353640;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.18px;
    margin: 0;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
}
.fl_right{ float: right; }
.step_sec1 img {
    margin-left: 32px; 
    max-width: none; 
}
.inv_box {
   border-radius: 8px;
    border: 2px solid #407f7c;
    padding: 28px 35px 40px 33px;
    max-width: 458px;
}
.inv_box h4 {
    color: #ffffff;
    font-size: 36px;
    margin: 0 0 6px 0;
}
.inv_box img {
    display: block;
    margin: 0 0 35px;
}
.inv_box a {
    color: #005450;
    font-size: 18px;
    border-radius: 3px;
    display: inline-block;
    background-color: #fff;
    font-weight: 500;
        padding: 11.5px 27px;
}
.step_sec2 .step_wrapper {
    margin-left: -22px;
}
.step_sec2 h2 {
    line-height: normal;
    margin-top: -83px;
    color: #045e5a;
}
.step_sec2 .step_lt p {
    padding-right: 36px;
    color: #fff;
}

.step_sec.step_sec2 h3 {
    color: #fff;
}
 
/*.step_sec3 .step_imgwrap img {
       width: 640px;
    margin-left: 85px;
    max-width: none;
}*/
.step_sec3 .step_wrapper {
    margin-right: -24px;
    margin-top: 27px;
}
/*.step_sec4 .step_wrapper {
    margin-left: -14px;
    margin-top: 84px;
}*/
.step_sec4 .step_imgwrap {
    /*margin-left: -126px;*/
    text-align: center;
}
.step_sec2{ padding-top: 162px; padding-bottom: 162px; }
.step_sec3{ padding-top: 116px; background-color: #fff; padding-bottom: 115px; }

/*.step_sec1:before {
    content: "";
    position: absolute;
    width: 109px;
    height: 212px;
    background:url(../../assets/images/st_shap1.png);
    z-index: 1;
    top: -63px;
    left: 0;
}*/
.step_sec2 .step_lt,.step_sec4 .step_lt{ position: relative; }
/*.step_sec2 .step_lt:before{
    content:"";
    position: absolute;width: 125px; height: 138px;
    background:url(../../assets/images/st_shap2.png);
        left: -179px;
    top: -111px;
}*/
/*.step_sec3:before {
    content: "";
    position: absolute;
    width: 172px;
    height: 181px;
    background:url(../../assets/images/st_shap3.png);
    z-index: 1;
    top: 145px;
    right: 77px;
}*/

/*.step_sec4 .step_lt:before{
    content:"";
    position: absolute;width: 237px; height: 115px;
    background:url(../../assets/images/st_shap5.png);
        left: -117px;
    top: 108px;
}*/

/*.step_sec4:before {
    content: "";
    position: absolute;
    width: 77px;
    height: 182px;
    background:url(../../assets/images/st_shap4.png);
    z-index: 1;
    top: 13px;
    left: 96px;
}*/
.abt_sec {
    background: #031825;
    overflow: hidden;
    padding-top: 115px;
    padding-bottom: 64px;
    position: relative;
}
/*.abt_sec:before{
    content: "";
    position: absolute;
    width: 130px;
    height: 159px;
    background:url(../../assets/images/share_3.png);
    z-index: 1;
    top: 104px;
    left: 186px;
}*/
.web_col1 {
    width: 43%;
    float: left;
}
.web_col2 {
    float: right;
    width: 48%;
}
.web_col1 h2 {
    color: #ffffff;
    font-size: 54px;
    margin: 0;
    text-align: right;
    line-height: 60px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}
.web_col2 p {
    margin: 0;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.18px;
        font-family: 'Inter', sans-serif;
}
.web_col2 a {
       color: #031b29;
    font-size: 18px;
    border-radius: 25px;
    display: inline-block;
     background: var(--moz-linear-btnbg);
         background: var(--webkit-btnbg);
    background: var(--btnbg);
    font-weight: 500;
        padding: 11.5px 34px;
        margin-top: 25px;
        display: inline-block;
         font-family: 'Poppins', sans-serif;
}

.convi_sec {
    /*background: #031825;*/
    position: relative; 
        font-family: 'Poppins', sans-serif;
            padding-bottom: 82px;
            padding-top: 111px;
}
.convi_sec h2 {
    margin: 0 0 45px 0;
    text-align: center;
    color: #ffffff;
    font-size: 54px;
}
.drp_row:after {
    content: "";
    display: table;
    clear: both;
}
.drp_row {
    display: grid;
    grid-template-columns: 46.7% 46.7%;
    column-gap: 80px;
    text-align: center;
    position: relative;
}
.drp_col {
    /*border: 2px dashed #45565e;*/
    border-radius: 39px;
    padding: 31px 60px 62px;
    background-color: #068466;
}
.drp_col h3 {
    color: #ffffff;
    margin: 21px 0 9px 0;
    font-size: 30px;
}
.drp_col p {
    margin: 0;
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.18px;
    font-family: 'Inter', sans-serif;
}
.drp_col p a {
    color: #fff;
    border-bottom: 1px solid;
}
.drp_col p a:hover {
    color: #ffc776;
}
/*.drp_col:hover {
    background: #031926;
    border: 2px solid #95ffda;
    box-shadow: 0px 0px 50px 10px rgba(149,255,217,0.14);
}*/

/*.drp_row:before{
    content: "";
    position: absolute;
    width: 106px;
    height: 112px;
    background:url(../../assets/images/share_2.png);
    z-index: 1;
       top: -174px;
    right: -70px;
}*/

.footer_sec {
    /*background: #031723;*/
    text-align: center;
    /*border-top: 1px solid #1b3d3e;*/
    padding-top: 53px;
    padding-bottom: 159px;
    position: relative;
        font-family: 'Inter', sans-serif;
        font-weight: 300;
}


.ftr_menu li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 17px;
    position: relative;
}
.ftr_menu li:after{
    content: "";
    background: #97a1a5;
    width: 2px;
    height: 2px;
}
.ftr_menu li a {
    color: #7fa9a7;
    font-size: 18px;
    letter-spacing: 0.18px;
    display: inline-block;
}
.ftr_menu li:last-child:after{ content: none; }
.ftr_menu li:after {
    content: "";
    background: #97a1a5;
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    right: 0;
    top: 10px;
}
.ftr_menu ul {
    margin: 40px 0 0;
}
.ftr_copy {
    color: #7fa9a7;
    font-size: 18px;
    letter-spacing: 0.18px;
    margin-top: 28px;
    display: block;
}
/*.footer_sec:before{
    content: "";
     position: absolute;
    width: 237px;
    height: 115px;
    background:url(../../assets/images/st_shap5.png);
    z-index: 1;
    top: -48px;
    left: 94px;
}
.footer_sec:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 944px;
      background:url(../../assets/images/wave2.png);
      bottom: 0;
      left: 0;
      background-size: 100% auto;
    background-position: bottom left;
        background-repeat: no-repeat;
}*/
.mobinav, .navclsbtn {
    display: none;
}
.fl_right{ float: right; order: 2; }


.frnt_header.fixed_header {
    padding: 10px 0;
    box-shadow: 0px 0px 20px rgba(151,255,214,0.10);
        z-index: 9999;
        background-image: url(../../assets/images/bg-repeat.jpg);
}
.fixed_header .usermenu li a {
    padding: 3px 0 6px;
    width: 110px;
}
.main_logo img {
    width: 195px;
    transition: all .2s ease-in-out;
}
.fixed_header .main_logo img {
    width: 150px;
}
/*.fixed_header .top_menu {
    margin-top: 9px;
}*/
/*.fixed_header .main_logo {
    margin-top: 3px;
}*/
.loginouter {
    overflow: hidden;
    height: 100vh;
        /*background: #031b29;*/
        position: relative;
        
} 
.loginouter:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: url(../../assets/images/wave3.png);*/
        background-size: 100% auto;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.log_wrapper {
    width: 100%;
    float: left;
    position: relative;
    height: 100%;
}
.log_col2 {
    background: #02131d;
}
.log_col1 h2 {
    color: #ffffff;
    font-size: 46px;
    line-height: 50px;
    margin: 0 0 31px 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.link_btn{
    border-radius: 5px;
    background-color: #068466;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 35px;
    display: inline-block;
     font-family: 'Roboto', sans-serif;
    border: 0;
    text-transform: uppercase;
}
.link_btn:hover{
     background:#ffc776; 
    color: #000;

}
.log_wrapper {
        /* display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;*/
            -webkit-box-pack: center;
            -webkit-justify-content: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
                -ms-flex-align: center;
                    align-items: center;
}
.log_col1 a.link_btn {
    padding: 11.5px 41px;
}

.login_box {
    border-radius: 10px;
    border: 1px solid #cfe1dd;
    background-color: #cfe1dd;
    padding: 50px;
}
.login_block {
    max-width: 490px;
    width: 100%;
    margin: 0 auto;
    display: grid;
}
.login_box h2 {
    color: #03504e;
    font-size: 47px;
    margin: 0 0 32px 0;
    letter-spacing: -0.64px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.gngo_link a {
    border-radius: 25px;
    background-color: #4285f4;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 12px 34px 12px 67px;
    position: relative;
}
.gngo_link a:before{
    content: "";
    position: absolute;
    width: 37px;
    height: 37px;    
    background: url(../../assets/images/gogle_icon.svg);
   left: 7px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.gngo_link {
    text-align: center;
}

.or_line {
    overflow: hidden;
    text-align: center;
    padding: 26px 0;
}
.or_line span {
    color: #b6ff9c;
    font-size: 16px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    position: relative;
}
 
.or_line span:before {
    content: "";
    position: absolute;
    width: 205px;
    height: 4px;
    background: url(../../assets/images/line1.png);
    left: -216px;
    top: 11px;
}
.or_line span:after {
    content: "";
    position: absolute;
    width: 205px;
    height: 4px;
    background: url(../../assets/images/line1.png);
    right: -216px;
    top: 11px;
      -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.login_box input:not([type="submit"]) {
    border-radius: 5px;
    background-color: #fff;
    border: 0;
    width: 100%;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    color: #2d4957;
    font-size: 16px;
    letter-spacing: 0.16px;
    padding: 23px;
    margin-top: 5px;
}
span.pskeep {
    font-size: 12px;
    letter-spacing: 0.24px;
    display: block;
    margin-top: 8px;
    /*margin-left: 25px;*/
      font-weight: 300;
    font-family: 'Inter', sans-serif;
}
.login_box li {
    width: 100%;
    margin-bottom: 17px;
}
.login_box input::-webkit-input-placeholder {  
  color: #a2c3d3;
}

.login_box input:-ms-input-placeholder {  
  color: #a2c3d3;
}

.login_box input::placeholder {
  color: #a2c3d3;
}
.login_box input[type="submit"] {
    /*background: var(--moz-linear-btnbg);
    background: var(--webkit-btnbg);
    background: var(--btnbg);*/
    background: #068466;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 19.5px 0;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    border: 0;
}
.frg_link {
    text-align: center;
    margin-top: 30px;
}
.frg_link a, .frg_link span {
    color: #068466;
    font-size: 17px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}


 
.log_col1:after{
    content: "";
     position: absolute;
    width: 237px;
    height: 115px;
    background:url(../../assets/images/st_shap5.png);
    z-index: 1;
       top: 107px;
    right: 148px;
    background-size: contain;
    background-repeat: no-repeat;
}
.log_col1:before {
       content: "";
    position: absolute;
    z-index: 1;
    bottom: 67px;
    left: 0;
    width: 109px;
    height: 212px;
    background: url(../../assets/images/st_shap1.png);
    background-size: contain;
    background-repeat: no-repeat;
     
}
.logo_login{
    text-align: center;
    margin-top: 100px;
    margin-bottom: 40px;
}
.logo_login img {
    width: 230px;
}
.log_col2:after{
   content: "";
    position: absolute;
    width: 106px;
    height: 112px;
    background:url(../../assets/images/share_2.png);
        z-index: 1;
   top: 93px;
    right: 73px;
    background-size: contain;
    background-repeat: no-repeat;
}
.log_col2:before {
       content: "";
    position: absolute;
    width: 130px;
    height: 159px;
   background:url(../../assets/images/share_3.png);
    z-index: 1;
    bottom:60px;
    left: 155px;
    background-size: contain;
    background-repeat: no-repeat;
     
}

.login_box p.sub_text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    margin: -25px 0 30px 0;
    text-align: center;
}
.error {
    color: #f00;
    font-size: 14px;
    margin-top: 15px;
    display: block;
}

.step_sec1 {
    background-color: #fff;
    padding-bottom: 70px;
}
.inv_box p {
    color: #fff;
    margin-bottom: 24px;
}
.step_sec2 .step_lt {
    margin-top: 52px;
}
.step_sec2:before {
    width: 206px;
    height: 269px;
    content: "";
    background-image: url(../../assets/images/yellow-shape2.svg);
    position: absolute;
    top: 0;
    left: 0;
}
.step_sec2:after {
    width: 366px;
    height: 162px;
    content: "";
    background-image: url(../../assets/images/line2.svg);
    position: absolute;
    top: 0;
    left: 0;
    background-position: -24px -4px;
    background-repeat: no-repeat;
}
.step_sec4:before {
    width: 373px;
    height: 333px;
    content: "";
    background-image: url(../../assets/images/yellow-shape3.svg);
    position: absolute;
    bottom: 0;
    right: 0;
}
.step_sec4:after {
    width: 425px;
    height: 312px;
    content: "";
    background-image: url(../../assets/images/line3.svg);
    position: absolute;
    bottom: 0;
    right: 0;
}
.step_sec3 .step_imgwrap {
    text-align: center;
}
.step_sec.step_sec4 h2 {
    color: #045e5a;
}
.step_sec.step_sec4 h3, .step_sec.step_sec4 p {
    color: #fff;
}
.step_sec4 {
    padding-top: 150px;
    padding-bottom: 162px;
}
.hw_headblock.au_headblock {
    padding-top: 58px;
}
.au_headblock .hw_midtitle {
    top: 173px;
}
.ab_cols {
    background-color: #fff;
    padding-bottom: 155px;
}
.ab_left {
    width: 46%;
    float: left;
}
.ab_right {
    width: 48%;
    float: right;
}
.ab_cols:after {
    content: "";
    display: block;
    clear: both;
}
.ab_right p {
    color: #353640;
    font-size: 18px;
    line-height: 1.55;
    letter-spacing: 0.18px;
    margin: 0 0 30px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}
.ab_btn {
    padding-top: 16px;
}
.dc_img {
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}
.footer_sec:before, .footer_sec:after {
    left: 0;
    right: inherit;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.loginonly .login_box{
    border-radius: 10px 10px 0 0;
}
.loginonly .log_inwrap{
    background: #b5d0ca;
    border-radius: 0 0 10px 10px;
    text-align: center;
}
.loginonly .log_inwrap p{
    font-family: "Graphik-Semibold";
    padding-top: 10px;
    margin-bottom: 5px;
    font-size: 18px;
}
.loginonly .log_inwrap .frg_link{
    margin-top: 5px;
    margin-bottom: 20px;
}


.page_top {
    padding-top: 110px;
}
.inner_banner {
    background: #025552;
    height: 394px;

      display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
                -ms-flex-align: center;
                    align-items: center;
                    position: relative;
}
.inner_banner h1 {
    margin: 0 0 13px 0;
    text-align: center;
    color: #ffffff;
    font-size: 66px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: normal;
}
.inner_banner p {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    margin: 0;
    line-height: 30px;
    letter-spacing: 0.18px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
}
.row{  margin:0 -10px; }
.row:after{ content: ""; clear: both; display: table; }

.col1{ width:8.33333333; }
.col2{ width:16.66666667%; }
.col3{ width:25%; }
.col4{width:33.33333333%;  }
.col5{width:41.66666667%;  }
.col6{ width: 60%;}
.col7{ width:58.33333333%;}
.col8{ width:66.66666667%;}
.col9{ width:75%;}
.col10{width:83.33333333%;}
.col11{width:91.66666667%;}
.col12{width:100%;}

.abt_sec1 {
    padding-top: 113px;
    background: #f8f8f8;
    padding-bottom: 115px;
}
.abt_sec1 h2{ 
     font-size: var(--heading2-size);
     font-weight: var(--heading2-weight);
         font-family: 'Poppins', sans-serif;
            margin: -7px 0 0 0;
    line-height: 1.2;
}

.col{ padding: 0 10px; float: left; }

.abt_sec1 p {
    font-size: 18px;
    color: #353640;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.18px;
    margin: 0 0 28px 0;
        font-family: 'Inter', sans-serif;
}
.grid_1350{ max-width: 1350px; margin: 0 auto; }


.abt_sec1 .col7 {
    padding-left: 70px;
}
.abt_sec1 p:last-child{ margin-bottom: 0; }
.abtool_sec {
    padding-top: 104px;
}
.abtool_sec h3 {
    font-size: 24px;
    margin: 17px 0 10px 0;
    font-family: 'Poppins', sans-serif;
    color: #353640;
    font-weight: 600;
}
.abtool_sec p {
    color: #353640;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    letter-spacing: 0.16px;
    line-height: 24px;
}
.abtool_sec .col:not(.abtool_sec .col:nth-child(4),.abtool_sec .col:nth-child(5),.abtool_sec .col:nth-child(6)){
    min-height: 360px;
}
.abtool_sec img {
    min-height: 82px;
}
.tl_box {
    max-width: 400px;
}
.tl_box p{
    max-width: 352px;
}
.d_flex{
     display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
}
.abt_sec2 {
    padding-top: 77px;
    padding-bottom: 84px;
}
.abt_sec2 h2 {
    font-size: var(--heading3-size);
    font-weight: var(--heading2-weight);
    font-family: 'Poppins', sans-serif;
    margin: 0 0 70px 0;
    text-align: center;
}
.abt_sec2 p {
    color: #353640;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    letter-spacing: 0.18px;
    line-height: 28px;
    margin: 0 0 30px 0;
}
.abt_sec2 .col5 {
    width: 44%;
}
.abt_sec2 .col7 {
    width: 56%;
    padding-left: 30px;margin-bottom: 50px;
}
.abt_sec2 .col5 img {
    margin-top: 42px;
}
.abt_sec2 p:last-child{ margin-bottom: 0; }

.pro_meta{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav_rt .fl_right {
    display: flex;
    align-items: center;
}
.frg_link p {
    line-height: 1.5;
    margin: 0 0 15px;
}