@media screen and (min-width:992px) {


}
@media screen and (max-width:1700px) {
	.page_wrapper:before {
    left: 5%;
    width: 100px;
    height: 122px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 11%;
	}
	.page_body2 .page_wrapper:after {
    right: 0;
    width: 165px;
    height: 81px;
    background-size: contain;
	}
	.projects_grid {
		margin-bottom: 40px;
	}
	.pricingpage .page_wrapper:after{
		bottom: 40px;
	}
}
@media screen and (max-width:1600px) {

	.page_body2 .page_wrapper:after{
		    width: 135px;
    height: 65px;
	}
	.project-header{
		margin-bottom: 40px;
	}
	.pricingpage .page_wrapper:before {
    	top: 0;
	}
	.ourprc_welcome p{
		font-size: 33px;
    line-height: 50px;max-width: 1023px;
	}
	.ourprc_welcome {
    margin-bottom: 40px;
    margin-top: 50px;
	} 
}
@media screen and (max-width:1500px){
	
	html {
    	--container2: 1300px;
	}

	.page_wrapper:before {
       width: 80px;
	}
	.page_wrapper:after { 
    width: 188px;
    height: 91px; 
    bottom: 45px;
    right: 46px;
    background-size: contain;
    background-repeat: no-repeat;
	}
	.no_design h2{ font-size: 38px; }
	.page_body2 .page_wrapper:before{
    	left: 0%;    width: 60px;
	}
	.project-header{
		padding: 24px 27px;
	}
	.prplan_row {
    	padding: 40px 0 120px;
	}
	.team_page .page_wrapper, .inv_page .page_wrapper,
	.surp_page .page_wrapper {
    	padding-top: 60px;
	}
	.mya_item h1, .mya_item h2{
		font-size: 35px;
	}
	.mya_row {    padding-top: 67px;}

	.project-header {
		padding: 35px 0 10px;
	}
	.view_fullpage .des_single .shot-header {
    	margin-top: 20px;
	}
	.pagenation button {
		font-size: 16px;
	}
}
@media screen and (max-width:1400px) {
	html {
    	--container2: 1250px;
	}
	.pricingpage .page_wrapper:after {
    bottom: 14px;
	}
	.prj_meta h3.project-title {
		font-size: 16px;
	}
}
@media screen and (max-width:1300px) {
	html {
    	--container2: 1170px;
	}
	.pricingpage .page_wrapper:before{
		left: 2%;
	}
	.project_ct_outer {
	    margin: 0 auto;
	    max-width: 1050px;
	}
	.np_dummy {
		padding: 100px 0;
	}
}
@media screen and (max-width:1200px){

	.no_design_row {    height: calc(100vh - 104px);}
	.no_design img {
    	width: 120px;
	}
	.no_design h2 {
    font-size: 34px;
    margin-top: 13px;
	}
	.no_design p{ font-size: 17px; }
	.no_design a.link_btn {
    padding: 8.5px 38px;
    margin-top: 25px;
    font-size: 17px;
	}
	.page_wrapper:before {
       left: 3%;
    width: 60px;
    top: 4%;
	}
	.page_wrapper:after {
    width: 125px;
    height: 55px;}
   .project-header textarea.title {
	    font-size: 20px;
	    height: 40px;
	    line-height: 40px;
	    padding-top: 0;
	}
	.project-header {
    	margin-bottom: 0;
	}
	.projects_item {
    padding: 0 20px;
	}
	.projects_grid {
		margin-bottom: 40px;
	}
	.prj_meta{
		    padding: 0 13px;
	}
	.actions span {
    margin-left: 10px;}
    .collaborators>ul{
    	    margin-right: 10px;
    	    padding-left: 0px;
    }
    .projects_grid .drag-add-more img {
    	width: 64px;
	}
	.prplan_grid{
		padding: 40px 0;
	}
	.prplan_grid h3{
		    margin: 30px 0 18px 0;
	}
	.pkg_price {
    font-size: 32px;}
    .pkg_price .dollar{
    	top: -16px;
    }
    .crtitle_rt {
    	padding-left: 11px;
    }
    .prplan_item{
    	padding: 0 7px;
    }
    .prplan_row {
    	margin: 0 -7px;    padding: 40px 0 55px;
	}
	.prplan_grid a.link_btn2{
		    padding: 9.5px 40px;font-size: 17px;
	}
	.mst_label{
		    margin-bottom: 20px;
	}
	.page_wrapper{
		    min-height: 700px;
	}
	.projects_item {
    	padding: 0 15px;
	}
	.prt_info{
		    padding: 16px 15px;
	}
	.project_ct_outer {
	    margin: 0 auto;
	    max-width: 960px;
	}
	.prjs_srch_outer {
		margin-bottom: 40px;
	}
	.pg_boxwrapper {
    margin-top: 104px;
	}
	.team_page .page_wrapper, .inv_page .page_wrapper,
	.surp_page .page_wrapper {
    	padding-top: 45px;
	}
	.team_page h2, .inv_page h2{
		    font-size: 35px;
	}
	.tm_inrow input.rbt-input-main{
		    padding: 16px 25px;
	}
	.mya_item{
		    padding: 40px 35px;    min-height: 350px;
	}
	.mya_item h1, .mya_item h2 {
    	font-size: 32px;    margin: 0 0 20px 0;
	}
	.mya_row {
    	padding-top: 50px;
	}
	.mya_item td{
		    font-size: 17px;
	}
	.mya_col1{
		    padding: 0 15px;
	}
	.mya_row{
		    margin: 0 -15px;
	}
	.mya_item input:not([type="submit"]){
		padding: 19px 20px;
	}
	.mya_item input[type="submit"]{
		padding: 15.5px 0;
	}
	.modal-main h2 {
		font-size: 30px;
	}
	.modal-main textarea {
		padding: 10px 15px !important;
    	font-size: 16px !important;
	}
	.prt_info h2 {
		font-size: 17px;
	}
	.view_fullpage .des_single .shot-header {
	    margin-top: 35px;
	}
	.many_title {
		font-size: 17px;
	}
	.billhead .inner button {
		font-size: 16px;
		padding: 14px 25px;
	}
	.price .prplan_item .prplan_grid {
		padding-bottom: 15px;
	}
	.price.prplan_row {
		padding-bottom: 60px;
	}
}
@media (min-width:992px) and (max-width:1200px) {

	html {
    	--container2: 970px;
	}

	.container {width: 970px;}
	.prcefea_box{    margin-right: 25px;width: 31.6%;}
	 .ourprc_welcome p {
    font-size: 28px;
    line-height: 46px;
    max-width: 870px;
	}
	.whyfb_outer h2{    font-size: 29px;    margin: 45px 0 20px 0;}
	.whyfb_outer{padding-bottom: 60px;}
	.pricefea_outer {    padding-bottom: 70px;}
	.prcefea_box{    padding: 35px;    min-height: 305px;}
	.free_feed{    padding: 65px 0 75px;}
	.free_feed h2{    font-size: 39px;    line-height: 47px;}
	.free_feed a {
    font-size: 19px;
    padding: 14px 32px;
    margin-top: 30px;
	}	 
	.prs_newsletter{    padding-top: 65px;    padding-bottom: 75px;}
	.prs_newsletter h3{font-size: 35px;    line-height: 50px;}
	.newsleter_subcribe{margin: 35px auto 0;}
	.newsleter_subcribe button{padding: 15px 0;    font-size: 17px;}
	.newsleter_subcribe input{    padding: 15px 15px;}
	.footer{    padding: 25px 0;}
	.plans_grid{    margin: 40px auto 0;}

	.shot-header{
		width: 90%;
		    max-width: 700px;
		        margin-top: 0;
	}
	.shot-header .title, .shot-header .editable textarea.title{
		    font-size: 22px;
		    padding-right:0;
	}
 	.shot-header .editable textarea.title{ width:100%; }
	.title .version{
		    font-size: 20px;
	}
	.comments-sidebar{
		    top: 104px;
	}
}
@media screen and (max-width:1200px){

	.commenter .inpbox{
		padding: 12px 15px
	}

	.commenter .pf_pic {
    width: 35px;
    padding: 7px 0 0 0;
    margin-right: 1.5%;
	}
	.commenter .pf_pic span {
     
    padding: 4px 6px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: block;
	}
	.screen .image{
		overflow: scroll;
	}
}
@media screen and (max-width:991px) {
	html {
    	--container2: 720px;
	}
	.container {width: 720px;}
	.main_header{    padding: 20px 0;} 
	.ourprc_right {
    float: none;
    width: 100%;
    padding: 0;
    text-align: center;
    margin-bottom: 50px;
	}
	.ourprc_left {
    width: 100%;
    float: none;
	}
	.ourprc_welcome p{    font-size:27px;    line-height: 45px;}
	.plans_grid{margin: 35px auto 0;}
	.pr_plans button{padding: 10px 16px;} 
	.tkfeed{    margin-top: 45px;}
	.whyfb_right {
    width: 100%;
    float: none;
    margin-top: 45px;
	}
	.whyfb_left {
    width: 100%;
    float: none;
    max-width: 500px;
    margin: auto;
	}
	.whyfb_outer h2 {
    font-size: 34px;
    margin: 0px 0 21px 0;
    }
    .whyfb_outer{padding-bottom: 40px;}
    .prcefea_box {
    width: 100%;
    float: none;
    padding:40px 30px;
    margin-right: 0;
   	margin: 0 auto 30px;
    max-width: 500px;
    min-height:auto;
	}
	.prcefea_box:nth-child(3){margin: 0 auto;}
	.pricefea_outer {
    	padding-bottom: 70px;
	}
	.free_feed{    padding: 55px 0 75px;}
	.free_feed h2{    font-size: 38px;    line-height: 50px;}
	.free_feed a {
    font-size: 18px;
    padding: 15px 34px;
    margin-top: 26px;
	}
	.prs_newsletter{    padding-top: 65px;    padding-bottom: 60px;}
	.prs_newsletter h3{    font-size: 31px;    line-height: 45px;}
	.newsle_user{    margin-top: 20px;}
	.newsleter_subcribe input{    padding: 15px 15px;}
	.newsleter_subcribe button{    padding: 15px 0;    font-size: 17px;}
	.newsleter_subcribe{    margin: 35px auto 0;}
	.he_share a{    padding: 10.5px 20px;}
	.footer{    padding: 25px 0;}

	.no_design_row {
    	height: calc(100vh - 80px);
	}
	.page_wrapper:before {
    
    width: 50px; 
	}
	.no_design img {
    width: 98px;
	}
	.no_design h2 {
    font-size: 30px;
    }	
    .no_design p {
    	font-size: 16px;
	}
	.page_wrapper:after {
    width: 110px;}

    .head_profile {
    float: right;
    position: relative;
    display: inline-block;
	}
	.user_nm button {
    width: 38px;
    height: 38px;font-size: 17px;}
    .page_wrapper{
    	    background-size: auto 700px;
    }
    .project-header {
    	margin-bottom: 35px;
	}
	.collaborators-container .add-new {
    font-size: 16px;
    padding: 8px 26px;
	}
	.projects_item {
    padding: 0 10px;
    width: 50%;
	}
	.projects_grid{margin-bottom: 25px;}
	.all_projects_list {
    	margin: 0 -10px;
	}
	.all_projects_row {
    	padding-bottom: 50px;
	}
	.prplan_item{ width: 50%; }
	.pkg_price {
    	font-size: 28px;
	}
	.many_title{
		font-size: 17px;
	}
	.prplan_grid h3 {
    	margin: 20px 0 16px 0;
	}
	.project-thumb-dropdown {
		left: -58px;
	}
	 
	button.share_button {
    float: none;
    clear: both;
    display: block;
    width: auto;
    margin-top: 14px;
        padding: 8px 15px;
	}
	.all_projects_row .share-link-container .share-link-text {
    width: 100%;
	}
	.sharethum_drop {
		right: 12%;
	}
	.share-link-container label	{    margin-bottom: 12px;}
	.share-link{
	    margin-bottom: 17px;
	}
	.prjs_srch_outer .link_btn.sortby img {
	    margin-right: 5px;
	}
	.prjs_serch input{
		padding: 16px 40px 14px 15px;
	}
	
	
	.prttitle_left {
    	width: 75%;    
	}
	.page_body.bg_light {
   		 padding-top: 40px;
	}
	.project_ct_outer{
		width: 720px;

	}
	.page_body2 {
   	 padding-top: 78px;
	}

	.crtitle_rt {
    width: 100%;
    float: none;
    display: block;
	}
	.project-header .collaborators-container {
    float: none;
    display: block;
	}
	.project-header .searchbox {
    float: none;}
    .project_ct_outer .prjs_srch_outer {
    	margin-bottom: 0;   margin-top: 25px;
	}
	.pg_boxwrapper {
    	margin-top: 78px;
	}
	.share_modalmain{
		    padding: 30px 26px;    max-width: 500px;
	}
	.share-link-container .share-link-text{
		margin-right: 1%;
	}
	.team_page h2, .inv_page h2 {
    font-size: 33px;
    line-height: normal;
    margin-bottom: 9px;
	}
	.teammates-list {
    margin-top: 5px;}
    .teammates-list table th{
    	    font-size: 18px;
    }

     .inv_page table thead tr { display:none; }
    .inv_page table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
	  }
	  
	  .inv_page table td {
	    border-bottom: 1px solid #ddd;
	    display: block; 
	    text-align: right;
	  }
	  
	  .inv_page table td::before {
	    /*
	    * aria-label has no advantage, it won't be read inside a table
	    content: attr(aria-label);
	    */
	    content: attr(data-label);
	    float: left;
	    font-weight: bold; 
	    margin-right: 10px;
	    color: #01544f;
	    font-family: "Graphik-Semibold";
	  }
	  
	  .inv_page table td:last-child {
	    border-bottom: 0;
	  }

	  .mya_item {
    	padding: 30px 25px;
    	min-height: 320px;
	}
	.mya_item h1, .mya_item h2 {
    font-size: 29px;
    margin: 0 0 15px;
	}
	.mya_item td{
		    word-break: break-word;
	}
	.mya_item input:not([type="submit"]) {
    	padding: 15px 20px;
	}
	.mya_item input[type="submit"] {
    	padding: 12.5px 0;
	}

	.surp_page .row {
    display: block;}
    .surp_page .col7{ width:100%; float:none; }
	.surp_page .col5{ width:100%; float:none;margin-top: 45px; }
	.surp_page h1{
		    margin: 0 0 18px 0;
	}
	.surp_page img {
    max-width: 450px;
    margin: auto;
    display: block;
	}
	.suprt_page .teammates-list thead {
    	display: none;
	}
	.suprt_page .teammates-list td {
    width: 100%;
    display: block;
        text-align: right;
	}
	.suprt_page .teammates-list td:before {
    content: attr(data-label);
    float: left;
    font-family: "Graphik-Semibold";
	}	
	.suprt_page .teammates-list td span {
    display: inline-block;
    margin: 0;
	}
	.teammates-list .btn{
		    font-size: 17px; 
    padding: 10px 15px;
	}
	.suprt_page .teammates-list tr {
	    border-bottom: 15px solid transparent;
	}

	.shot-header{
		width: 90%;
		    max-width: 700px;
		        margin-top: 0;
	}
	.shot-header .title, .shot-header .editable textarea.title{
		    font-size: 22px;
		    padding-right:0;
	}
 	.shot-header .editable textarea.title{ width:100%; }
 	
	.title .version{
		    font-size: 20px;
	}
	.comments-sidebar{
		    top: 78px;
	}
	.frnt_header.fixed_header{
		    padding: 10px 0;
	}
	.fixed_body .comments-sidebar {
    	top: 58px;
	}
	.billhead .inner {
    	display: block;
	}
	.payment-balloon {
    
    top: 40%;
    left: 0;
    max-width: 470px;
        padding: 40px 20px;
    width: 90%;
    right: 0;	
    margin: auto;
	}
	#screenOptions>ul>li {
		margin-right: 5px;
	}
	.shot-header .title-container {
		max-width: calc(100% - 165px);
	}
	.regpop .popcontent .poptop h2 {
		font-size: 32px;
	}
	.regpop .popcontent .popmiddle {
		padding-bottom: 0;
	}
	.left_design {
		float: right;
	}
	.project-thumb-dropdown:before {
		left: 87px;
	}
	.logo_lt {
		width: 25%;
	}
	.sharethum_drop:before {
		right: 20%;
	}
	.billhead .inner button {
		padding: 12px 25px;
	}
	.price.prplan_row {
	    padding-bottom: 10px;
	}
	.close_popup{
		    width: 40px;
    height: 40px;
	}
}
@media screen and (max-width:768px) {}

@media screen and (max-width:767px) {

	html {
    	--container2:96%;
	}
	.container {width: 100%;  padding:0 20px;}
	.container:after{ clear: both;content: ""; display: table; }
	
	.pr_plans {
    float: left;
    margin: 0 20px 20px 0;
    width: 48%;
	}

	.pr_plans:nth-child(2n) {
    	margin-right: 0;
	}
	.tkfeed {
    	margin-top: 35px;
	}
	.whyfb_outer p{    font-size: 17px;}
	.logo {
    float: none;
    margin: 0 auto 13px;
    text-align: center;
	}
	.he_share a {
    	padding: 8px 13px;
	}
	.prs_newsletter h3 {
    	font-size: 29px;
    	line-height: 39px;
	}
	.page_wrapper:before { 
    width: 48px; 
    height: 79px;
	}

	.page_wrapper:after {
    width: 84px;
    bottom: 20px;
    right: 28px;
	}
	.no_design h2 {
    	font-size: 28px;
	}
	.page_body2 .page_wrapper:before {
    	display: none;
	}
	.project-header {
    padding: 8px 15px 7px;
	}
	.crtitle_lt {
    	padding-top: 5px;
	}
	.project-header textarea.title {
    font-size: 18px;}
    .ourprc_welcome p {
    font-size: 24px;
    line-height: 41px;
	}
	.share_modalmain{
		padding: 20px;
	}
	.share_orlink span.share-link-text {
	    width: 100%;
	    margin-bottom: 9px;
	    margin-right: 0;
	}
	.prjs_serch input{
		padding: 15px 40px 14px 15px;
	}
	.prjs_srch_outer .link_btn.crate_btn{
		 font-size: 16px;
	}
	.prjs_srch_outer{
		 padding-top: 55px;
		 margin-bottom: 50px;
	}
	.prttitle_left {
   		 width: 73%;
	}
	.all_projects_row {
    	padding-bottom: 15px;
	}
	.arc_btns_row {
    	margin-top: 35px;
	}
	.prjs_srch_outer .link_btn{
		margin-right: 10px;
	}
	.project_ct_outer{
		max-width: 100%;
		padding: 10px 25px;
	}
	.collaborators-container .add-new {
	    font-size: 15px;
    	padding: 8px 25px;
	}
	.crtitle_rt{
		width: 36%;
	}
	.page_body2 {
   	 padding-top:80px;
	}
	.singles_page {
    	padding-top: 50px;
	}
	.project_ct_outer .project-header{
		    margin-top: 18px;
	}
	.left_design .designs{
		margin-left: 0;
	}
	.project_ct_outer .project-header {
    padding-left: 0;
    padding-right: 0;
	}

	.project_ct_outer .crtitle_rt {
    	width: 100%;
	}
	.project_ct_outer .collaborators {
    display: block;
	}
	.project_ct_outer .share_modalmain{
		    max-width: 400px;
	}
	.new-button.light.primary{
		    width: 120px;
	}
	.team_page .page_wrapper, .inv_page .page_wrapper,
	.surp_page .page_wrapper {
    padding-top: 30px;
	}
	.teammates-list table td span {
    display: block;
    margin-top: 9px;
	}
	.teammates-list{
		    word-break: break-word;
	}
	.mya_col1 {
    width: 100%;
    float: none;
    padding: 0 15px;
    max-width: 550px;
    margin: 0 auto 26px;
	}
	.mya_item{
		    min-height: auto;
	}
	.mya_item h1, .mya_item h2 {
    	font-size: 26px;
	}
	.mya_item input[type="submit"] {
    	padding: 11.5px 0;
	}
	.mya_row {
    	padding-top: 40px;
	}
	.contpg_sec textarea{
		    height: 100px;
	}
	.title .version {
    	font-size: 18px;
	}
	.title .version {
    font-size: 18px;
    width: 50px;
    height: 50px;
	}
	.shot-header .ver_bg2 button:after {
    
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #fff;
    
    top: 23px;
    left: 34px; 
	}
	.shot-header .title, .shot-header .editable textarea.title {
    font-size: 20px;}
    
	.shot-header {
    width: 95%;
	}
	.comments-sidebar .sidebar-annotation{
		    padding: 27px 20px 30px 20px;
	}
	.comments-sidebar .sectionhead{
		    padding: 20px 0px 0px 20px;
	}
	.comments-sidebar {
    	top: 81px;
	}
	.modal-main {
		padding: 20px;
		border-radius: 15px;
	}
	.modal-main textarea {
		border-radius: 10px;
	}
	.modal-main button {
		margin: 0 5px;
	}
	.frg_link a, .frg_link span {
		font-size: 16px;
	}
	.login_box li label, .frg_link p {
	    font-size: 15px;
	}
	.shot-header .project-thumb-dropdown {
		right: 0;
	}
	.project-thumb-dropdown:before {
	    left: 111px;
	}
	.all_projects_outer .project-thumb-dropdown:before {
	    left: 75px;
	}
	.shot-header .version-menu:after {
		margin-left: 15px;
	}
	.des_single .project-thumb-dropdown:before {
	    left: 112px;
	}
	.tm_inrow {
		font-size: 20px;
	}
	.tm_inrow .new-button.light.primary {
		width: auto;
	}
	.container2 .pritable_row {
	    margin-top: 35px;
	}
	.billhead .inner button {
	    font-size: 15px;
	}
	.teammates-list tr, .teammates-list tr td {
	    display: block;
	    width: 100%;
	}
	.teammates-list thead {
		display: none;
	}
}
@media screen and (max-width:667px) {
	.commenter .input{
		width: calc(100% - 10%);
	}
	.commenter .tools {
	    padding: 20px 15px;
	}
	.modal-main h2 {
		margin-bottom: 15px;
	}
	.modal-main h2 {
	    font-size: 28px;
	}
	.share_modalmain {
		top: 145%;
	}
	.tm_inrow input.rbt-input-main {
	    padding: 12px 15px;
	}
	.teammates-list table td {
		padding: 10px 20px 20px;
	}
	.teammates-list table tr:last-child td {
		border-bottom: 0;
	}
	.billhead .inner .lt {
	    margin-bottom: 10px;
	}
	.billhead {
	    padding-top: 35px;
	}
}

@media screen and (max-width:640px) {
	.prjs_serch_icon{
		display: block;
		float: left;		 
	}
	.crtitle_rt {
		 width: 48%;
	}
	.prjs_serch{
		display: none;
	}
	.prjs_serch_icon .show.prjs_serch:before{
		width: 0;
    	height: 0;
    	border-left: 6px solid transparent;
	    border-right: 6px solid transparent;
	    border-bottom: 9px solid #068466;
	    content: "";
	    position: absolute;
	    top: -9px;
	    left: 10px;
	    z-index:999;

	}
	.ser_icon {
		margin-right: 10px;
	}
	.prjs_srch_outer {
    padding-top: 35px;
    margin-bottom: 40px;
	}
	.left_design .designs {
    	 margin: 12px 10px;
	}
	.shot-header .editable {
		padding: 15px;
	}
	.regpop .popcontent {
		max-width: 90%;
	}
	.prjs_serch_icon .prjs_serch {
	    right: 10px;
	}
	.shot-header .project-thumb-dropdown {
		top: 60px;
	}
	.project-thumb-dropdown:before {
	    left: 115px;
	}
	.pro_single .collaborators-container .add-new {
		padding: 8px 20px;
	}
	.pro_single .share_modalmain:before {
		left: 37px;
	}
	.pro_single .share_modalmain h2 {
		font-size: 15px;
	}
	.des_single .project-thumb-dropdown:before {
	    left: 117px;
	}
	.tm_inrow p {
		font-size: 17px;
	}
}

@media screen and (max-width:570px) {
	.crtitle_rt {
		 margin-bottom: 15px;
	}
	.project-header .collaborators-container{
		float: none;
	}
	.left_design .designs {    
   		margin: 8px 15px;
	}
	.share_modalmain{
		left: -150%;
	}
	.share_modalmain:before{
		left: 47%;
	}
	.shot-header .title-container {
	    width: 100%;
	    float: none;
	    max-width: 100%;
	    margin-bottom: 15px;
	}
	.shot-header #screenOptions {
	    width: 100%;
	    float: none;
	    clear: both;
	}
	.shot-header .editable {
	    padding: 15px;
	    height: auto;
	    flex-wrap: wrap;
	}
	.title .version {
		margin-left: 0;
	}
	.view_fullpage .content {
	    padding-top: 30px;
	}
	#screenOptions>ul { 
	    margin: auto;
	    width: fit-content;
	}
	.modal-main button {
		font-size: 15px;
	}
	.regpop .popcontent .popbottom button, .billhead .inner button, .verify_pop a {
		font-size: 16px;
	}
	.regpop .popcontent {
		padding-bottom: 40px;
	}
	.shot-header .project-thumb-dropdown {
	    top: 120px;
	    right: 174px;
	}
	.logo_lt {
	    width: 30%;
	}
	.pro_single .share_modalmain:before {
	    left: 177px;
	}
	.shot-header .version-menu:after {
	    margin-left: 10px;
	}
	.des_single .project-thumb-dropdown:before {
	    left: 115px;
	}
	.pagenation button {
		padding: 12px 20px;
		margin: 0 4px;
	}
	.notifications-inline {
		margin-left: 0;
	}
}

@media screen and (max-width:533px) {
	.dash_header .logo_lt {
	    width: auto;
	    float: left;
	    margin-bottom: 0;
	}
	.crtitle_rt {
	    width: 100%;
	    float: none;
	}
	.crtitle_lt {
	   	padding: 3px 15px 0 15px;
	    width: 100%;
	    float: none;
	}
	.project-header .collaborators-container {
    	float: none;
	}
	.project-header {
    	padding: 13px 20px;
	}
	.sharethum_drop{
		max-width: 400px;
	}	
	.commenter .tools .left,
	.commenter .tools .right {
	    width: 100%;
	    display: block;
	    text-align: center;
        height: auto;
	}
	.commenter .tools {
	    display: block;
	    text-align: center;
	    height: auto;
	}
	.Toolbar .toolbar-container {
    	float: none;
	}
	.commenter .tools .left ul,
	.redo-container {
	    width: max-content;
	    margin-left: auto;
	    margin-right: auto;
	}
	.commenter .input{
		width: calc(100% - 45px);
	}
	.projects_item {
	    padding: 0;
	    width: 100%;
	}
	.shot-header .project-thumb-dropdown {
		right: 157px;
	}
	.mya_item {
		padding: 25px 20px;
	}
}
@media screen and (max-width:480px){
	.projects_item {
    padding: 0;
    width: 100%;
    float: none;
    clear: both;
	}
	.all_projects_list {
    	margin: 0;
	}
	.ourprc_welcome{
		    margin-top: 23px;margin-bottom: 28px;
	}
	.ourprc_welcome p {
    font-size: 21px;
    line-height: 34px;
	}
	.prplan_item {
    width: 100%;
    float: none;
    clear: both;
	}
	.prplan_grid {
    	padding: 24px 0;
	}
	.share-link-container .new-button.light.primary {
    width: auto;
    padding: 6px 27px; 
	}
	.share_modalmain {
    	padding: 20px 20px;
	}
	.prjs_srch_outer .link_btn.crate_btn {
	    font-size: 16px;
	    position: absolute;
	    top: 80px;
	    padding: 8px;
	    width: 100%;
	    text-align: center;
	    margin-right: 0;
	}
	.prjs_srch_outer {
	    padding-top: 25px;
	    margin-bottom: 90px;
	}
	.ser_icon{
		padding: 8px;
		margin-right: 0;
	}
	.project-thumb-dropdown{
		left: -86px;
    	width: 200px;
	}
	.project-thumb-dropdown:before{
		left: 108px
	}
	.prttitle_left {
	    width: 75%;
	}
	.prt_right {
	    width: 80px;
	    padding-right: 10px;
	}
	.prjs_serch_icon .show.prjs_serch{
    	right: 0;
	}
	.share_modalmain:before {
    	left: 75%;
	}	
	.shot-header .title, .shot-header .editable textarea.title {
    	font-size: 18px;
	}
	.sharethum_drop {
	    right: 17%;
	}
	.shot-header .project-thumb-dropdown {
	    right: 125px;
	}
	.all_projects_outer .project-thumb-dropdown:before {
	    left: 125px;
	}
	.sharethum_drop:before {
	    right: 23%;
	}
	.all_projects_outer .prjs_srch_outer {
		margin-bottom: 70px;
		position: relative;
	}
	.des_single .project-thumb-dropdown:before {
	    left: 108px;
	}
	.no_del .sharethum_drop::before {
	  right: 14px;
	}
	.pagenation button {
		padding: 10px 12px;
		margin: 0 4px;
		font-size: 15px;
	}
	.pagenation button:nth-child(1) svg, .pagenation button:nth-child(2) svg {
	    margin-right: 7px;
	}
	.pagenation button:nth-child(3) svg {
	    margin-left: 7px;
	}
	.pagenation button svg {
	    width: 15px;
	    height: 15px;
	}
}

@media screen and (max-width:440px) {
	html {
    	--container2: 90%;
	}
	.page_wrapper:before {
    left: 5%;
    top: 2%;
	}
	.no_design img {
    	width: 84px;
	}
	.no_design h2 {
    	font-size: 25px;
	}
	.no_design a.link_btn {
    padding: 7.5px 30px;
    margin-top: 21px;
    font-size: 16px;
	}
	.projects_grid{
		    padding-top: 94%;
	}
	.ourprc_welcome p {
    font-size: 19px;
    line-height: 31px;
	}
	.prplan_row {
    	padding: 0px 0 55px;
	}
	.prplan_grid a.link_btn2{font-size: 16px;}
	.many_title {
    	font-size: 16px;
	}
	.share_modalmain {
    	padding: 18px 16px;
	}
	.team_page h2, .inv_page h2 {
	    font-size: 29px;
	}
    .tm_inrow p {
	    font-size: 17px;
	    line-height: 1.3;
	}
	.tm_inrow{
		padding: 22px 20px;
	}
	.teammates-list table th {
    	font-size: 17px;    padding: 16px 15px;
	}
	.teammates-list table td {
    padding: 16px 15px;}

    .mya_item h1, .mya_item h2 {
    	font-size: 24px;
	}
	.surp_page img {
    max-width: 300px;}
    .view_fullpage .content {
    	padding-top: 40px;
	}
	.shot-header #screenOptions{ padding-top:0; }
	
	.comments-sidebar {
    	width: 90%;
	}
	.redo-container {
		    width: 100%;
    	display: block;
	}
	.redo-container .opt{
		display: inline-block;
    vertical-align: middle;
	}
	.toolbar-container .redo-container .btn {
    margin-left: 0;
	}
	.toolbar-container .redo-container .btn button {
    
    margin: 10px auto 0;
    
    width: 100px;
	}
	.regpop .popcontent .popmiddle {
	    padding: 25px 15px 0;
	}
	.popcontent p {
		font-size: 15px;
	}
	.regpop .popcontent .poptop h2 {
	    font-size: 28px;
	}
	.regpop .popcontent {
	    padding-bottom: 30px;
	}
	.regpop .popcontent .poptop {
		padding: 100px 10px 25px;
	}
	.regpop .popcontent .poptop:before {
		top: 30px;
	}
	.regpop .popcontent .popbottom button, .billhead .inner button, .verify_pop a {
		padding: 12px 30px;
	}
	.shot-header .project-thumb-dropdown {
	    right: 94px;
	}
	.project_ct_outer {
		padding: 10px 20px;
	}
	.project-header {
	    padding: 0 20px;
	}
	.project_ct_outer .share_modalmain {
	    max-width: 350px;
	}
	.pagenation button {
		margin: 0 3px;
		font-size: 14px;
	}
	.mya_item td {
	    font-size: 16px;
	}
	.pagenation button {
		float: left;
	}
	.pagenation button:nth-child(2) {
		clear: left;
	    width: 48%;
	    margin-top: 15px;
	    margin-bottom: 15px;
	    display: flex;
	    justify-content:center;
	}
	.pagenation button:nth-child(3) {
	    width: 48%;
	    margin-top: 15px;
	    margin-bottom: 15px;
	    display: flex;
	    justify-content:center;
	}
	.pagenation {
		padding: 0 5px;
	}
	.left_design .button {
		font-size: 15px;
	}
	.tm_inrow textarea {
		font-size: 16px !important;
		padding: 0 15px !important;
	}
}
@media screen and (max-width:414px) {}
@media screen and (max-width:400px) {
	.popcontent p br {
		display: none;
	}
	.collaborators-container .add-new {
		padding-left: 20px;
		padding-right: 20px;
	}
	.shot-header .project-thumb-dropdown {
	    right: 76px;
	}
	.project_ct_outer .share_modalmain {
	    max-width: 300px;
	}
	.des_single .project-thumb-dropdown:before {
	    left: 108px;
	}
	.pagenation button {
	    font-size: 14px;
	}
	.pagenation button:nth-child(1) {
	    padding-left: 15px;
	}
	.pagenation button:nth-child(2) {
	    padding-right: 15px;
	}
	.pagenation button svg {
	    width: 13px;
	}
	.tm_inrow p {
		font-size: 16px;
	}
	.prjs_srch_outer .link_btn.sortby {
		padding-left: 14px;
	}
	.prjs_srch_outer .link_btn {
		margin-right: 0;
	}
	.left_design .designs {
		margin-left: 0;
		margin-right: 0;
	}
	.left_design {
		font-size: 14px;
	}
	.left_design .button {
	    font-size: 14px;
	}
}
@media screen and (max-width:360px) {
	.shot-header .project-thumb-dropdown {
	    right: 67px;
	}
}
@media screen and (max-width:320px) {}